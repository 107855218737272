import React from 'react'

const Logo = ({ color = '#000000' }) => (
  <svg title='Robotface' width="366px" height="102px" viewBox="0 0 366 102" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fillRule="evenodd" fill={color}>
      <path d="M131.786,71.069 C131.341,71.069 130.923,71.278 130.648,71.628 C122.419,82.101 109.638,88.826 95.279,88.826 C80.922,88.826 68.14,82.101 59.912,71.628 C59.637,71.278 59.219,71.069 58.773,71.069 L46.372,71.069 C45.251,71.069 44.548,72.288 45.114,73.254 C55.211,90.453 73.898,102 95.279,102 C116.662,102 135.348,90.453 145.446,73.254 C146.013,72.288 145.308,71.069 144.188,71.069 L131.786,71.069"></path>
      <path d="M84.489,55.971 C84.489,57.018 84.45,57.808 84.5,58.592 C84.555,59.476 84.174,59.755 83.32,59.743 C80.649,59.707 77.974,59.674 75.304,59.756 C74.137,59.792 73.865,59.378 73.869,58.28 C73.906,48.925 73.891,39.57 73.891,30.214 C73.891,21.336 73.914,12.458 73.8629022,3.581 C73.856,2.286 74.223,1.893 75.537,1.817 C78.103,1.669 80.656,1.299 83.205,0.953 C84.372,0.795 84.676,1.218 84.667,2.347 C84.619,8.265 84.641,14.184 84.652,20.102 C84.653,20.511 84.453,21.047 84.88,21.299 C85.343,21.574 85.646,21.061 85.967,20.828 C89.162,18.517 92.828,17.73 96.652,17.837 C104.589,18.057 110.894,21.255 114.47,28.622 C118.238,36.386 117.468,48.702 110.279,55.149 C104.049,60.735 93.523,61.979 86.861,57.719 C86.118,57.244 85.438,56.674 84.489,55.971 M105.864,39.09 C105.963,32.462 101.474,28.08 95.359,28.003 C89.204,27.925 84.639,32.689 84.6439959,38.973 C84.649,45.392 89.187,50.067 95.396,50.0490518 C101.535,50.031 105.875,45.488 105.864,39.09"></path>
      <path d="M47.545,60.18 C35.245,60.119 26.372,51.223 26.3669979,38.949 C26.362,26.531 35.375,17.589 48.006,17.77 C59.72,17.939 68.922,26.09 68.9160029,38.98 C68.911,52.091 59.316,60.16 47.545,60.18 M57.996,38.864 C57.969,32.689 53.423,27.836 47.053,28.122 C41.532,28.369 37.147,33.019 37.197,38.999 C37.249,45.296 41.637,49.733 47.766,49.687 C53.808,49.641 58.057,45.146 57.996,38.864"></path>
      <path d="M142.811,60.256 C130.511,60.192 121.603,51.248 121.657747,39.016 C121.713,26.527 130.656,17.663 143.297,17.847 C154.982,18.017 164.2,26.175 164.193004,39.068 C164.185,52.13 154.595,60.252 142.811,60.256 M142.852,28.241 C137.029,28.112 132.531,32.499 132.51496,38.986 C132.5,45.337 136.698,49.797 142.954,49.7721046 C149.004,49.748 153.257,45.35 153.271039,39.03 C153.286,32.728 148.946,28.234 142.852,28.241"></path>
      <path d="M175.335,44.018 C175.335,39.343 175.295,34.668 175.363,29.993 C175.379,28.762 175.071,28.277 173.776,28.344 C171.919,28.442 170.055,28.34 168.196,28.383 C167.37,28.402 167.001,28.208 167.024,27.276 C167.083,24.701 167.075,22.122 167.027,19.548 C167.009,18.592 167.405,18.272 168.293,18.282 C170.248,18.301 172.207,18.228 174.159,18.312 C175.153,18.355 175.363,18.002 175.355,17.089 C175.315,12.7 175.334,8.311 175.335,3.922 C175.335,1.999 175.342,2.034 177.227,1.839 C179.69,1.582 182.152,1.296 184.605,0.953 C185.84,0.781 186.278,1.122 186.257,2.449 C186.181,7.218 186.279,11.99 186.193,16.76 C186.168,18.075 186.587,18.347 187.811,18.313 C190.862,18.226 190.615,18.312 193.669,18.271 C194.698,18.258 195.131,18.595 195.107,19.674 C195.05,22.106 195.059,24.541 195.1,26.972 C195.116,28.008 194.757,28.409 193.683,28.389 C190.631,28.334 190.875,28.42 187.824,28.344 C186.569,28.314 186.195,28.722 186.2,29.98 C186.248,39.283 186.199,48.586 186.254,57.889 C186.264,59.301 185.916,59.835 184.424,59.763 C181.901,59.642 179.365,59.671 176.841,59.755 C175.613,59.797 175.303,59.342 175.315,58.188 C175.364,53.465 175.335,48.741 175.335,44.018"></path>
      <path d="M10.768,47.751 C10.768,51.771 10.736,55.015 10.787,58.258 C10.803,59.265 10.596,59.712 9.458,59.678 C6.742,59.598 4.02,59.614 1.303,59.672 C0.285,59.694 0.002,59.331 0.004,58.35 C0.029,45.423 0.032,32.496 -6.49281708e-06,19.569 C-0.002,18.452 0.458,18.184 1.448,18.197 C4.072,18.231 6.696,18.248 9.319,18.189 C10.433,18.164 10.921,18.518 10.781,19.669 C10.711,20.233 10.746,20.813 10.771,21.384 C10.783,21.686 10.568,22.13 11.011,22.243 C11.406,22.344 11.52,21.903 11.726,21.654 C14.825,17.913 18.859,17.244 23.37,18.089 C24.265,18.257 24.165,18.908 24.168,19.512 C24.18,21.993 24.175,24.473 24.173,26.954 C24.172,28.953 24.06,29.086 22.173,28.559 C20.228,28.016 18.366,28.341 16.58,29.034 C13.128,30.374 11.423,33.075 10.991,36.671 C10.517,40.616 10.917,44.576 10.768,47.751"></path>
      <path d="M260.108,21.866 C260.206,21.667 260.277,21.587 260.283,21.502 C260.518,18.638 260.517,18.637 263.501,18.638 C265.648,18.638 267.795,18.664 269.941,18.63 C270.905,18.614 271.293,18.957 271.291,19.965 C271.268,32.893 271.266,45.822 271.295007,58.75 C271.297,59.855 270.861,60.122 269.847,60.102 C267.224,60.049 264.597,60.018 261.977,60.112 C260.658,60.159 260.131,59.749 260.282,58.42 C260.333,57.98 260.558,57.352 260.113,57.087 C259.619,56.792 259.293,57.416 258.934,57.665 C255.132,60.301 250.849,61.074 246.398,60.487 C239.488,59.577 234.039,56.239 230.648,49.973 C228.987,46.904 228.256,43.588 228.194,40.094 C228.047,31.695 231.13,24.963 238.614,20.817 C244.566,17.52 250.856,17.18 257.156,20.025 C258.179,20.487 259.081,21.216 260.108,21.866 M260.289002,39.418 C260.292,32.968 255.833,28.282 249.759,28.351 C243.341,28.425 238.908,32.964 238.906,39.467 C238.905,45.691 243.515,50.378 249.658,50.4020927 C255.797,50.426 260.286,45.787 260.289002,39.418"></path>
      <path d="M297.008,61.044 C286.23,61.139 277.516,54.215 275.897,43.716 C274.096,32.037 279.826,21.843 292.154,19.157 C299.22,17.618 305.904,18.983 311.435,24.16 C312.356,25.022 312.415,25.574 311.424,26.411 C309.421,28.102 307.479,29.869 305.562,31.659 C304.848,32.325 304.357,32.313 303.644,31.646 C300.439,28.646 296.632,28.218 292.744,29.761 C288.921,31.28 286.961,34.464 286.572,38.521 C286.161,42.791 287.349,46.497 291.064,48.973 C294.422,51.211 298.072,51.361 301.666,49.628 C305.186,47.931 306.931,44.892 307.192,41.054 C307.265,39.983 307.558,39.663 308.605,39.687 C311.277,39.748 313.952,39.763 316.623,39.681 C317.738,39.647 318,40.048 317.992176,41.079 C317.929,49.491 311.944,57.532 303.884,59.992 C301.593,60.691 299.284,61.237 297.008,61.044"></path>
      <path d="M217.785,44.035 C217.785,48.759 217.753,53.483 217.807,58.206 C217.822,59.363 217.523,59.8 216.292,59.758 C213.671,59.668 211.043,59.668 208.422,59.758 C207.234,59.799 207.015,59.362 207.019,58.282 C207.058,48.93 207.012,39.578 207.072,30.227 C207.082,28.8 206.719,28.235 205.239,28.346 C203.72,28.46 202.186,28.344 200.66,28.382 C199.911,28.4 199.53,28.249 199.548,27.367 C199.6,24.743 199.601,22.118 199.549,19.495 C199.53,18.498 200.003,18.27 200.854,18.281 C202.524,18.303 204.195,18.26 205.863,18.302 C206.712,18.324 207.078,18.034 207.053,17.151 C207.01,15.625 207.026,14.097 207.047,12.57 C207.123,7.203 210.891,2.892 216.177,1.83 C219.593,1.143 222.88,1.292 226.161,2.43 C227.945,3.049 229.277,4.22 230.48,5.604 C230.96,6.156 230.863,6.581 230.361,7.061 C228.5,8.841 226.651,10.63 224.827,12.448 C224.304,12.969 223.965,12.951 223.485,12.393 C222.51,11.265 221.29,10.679 219.805,11.27 C218.283,11.876 217.761,13.107 217.769,14.7 C217.786,18.284 217.757,18.284 221.341,18.284 C223.488,18.284 222.335,18.313 224.482,18.272 C225.426,18.255 225.87,18.509 225.845,19.563 C225.781,22.09 225.778,24.621 225.844,27.148 C225.87,28.212 225.445,28.412 224.489,28.392 C221.771,28.334 222.351,28.368 219.631,28.372 C217.785,28.374 217.785,28.378 217.785,30.293 C217.785,34.874 217.785,39.454 217.785,44.035"></path>
      <path d="M348.634,43.201 C343.717,43.201 338.8,43.235 333.883,43.178 C332.596,43.162 332.393,43.466 332.842,44.672 C334.71,49.686 340.177,53.003 345.454,52.32 C348.322,51.947 350.999,51.282 352.897,48.865 C353.186,48.496 353.581,48.392 354.058,48.395 C357.172,48.412 360.288,48.426 363.404,48.389 C364.594,48.374 364.533,48.83 364.139,49.711 C362.148,54.143 358.796,57.209 354.437,59.136 C345.698,62.997 337.325,62.439 329.846,56.234 C323.669,51.108 321.33,44.269 322.608,36.391 C324.134,26.969 329.797,20.91 338.96,18.717 C350.026,16.067 360.569,22.387 364.091,32.812 C365.073,35.718 365.277,38.689 365.119,41.732 C365.059,42.906 364.621,43.225 363.531,43.218 C358.566,43.182 353.601,43.202 348.634,43.201 M343.79,34.974 C346.951,34.974 350.113,34.937 353.275,34.993 C354.478,35.015 354.731,34.658 354.232,33.577 C352.438,29.688 349.28,27.722 345.164,27.16 C340.283,26.493 335.091,29.249 333.329,33.368 C332.715,34.802 332.808,34.968 334.308,34.972 C337.468,34.982 340.629,34.975 343.79,34.974"></path>
    </g>
  </svg>
)

export default Logo
