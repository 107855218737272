
import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from './logo'

class Header extends Component {

  state = {
    visible: false,
    scrolled: false,
    showHeader: true,
    menuActive: false,
    color: 'white',
  }

  componentDidMount() {
    this.setColor()
    window.addEventListener('scroll', this.onScroll)

    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.onScroll()
      }, 200)
    })
	}

	componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
	}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // Trigger selectFocus after transition has completed
      this.setColor()
    }
  }

  onScroll = () => {

    //if (this.props.location.pathname === '/') return

    let header = document.querySelector('.header')
    if (!header) return

    if ( window.scrollY > 0 ) {
      header.classList.add('header--white')
      header.classList.add('header--scrolled')
    } else {
      header.classList.remove('header--scrolled')
    }

  }


  setColor() {
    switch (this.props.location.pathname) {
      case '/':
      case '/sample-page/':
      case '/phantom-4k':
      case '/motion-control':
      case '/studio':
      case '/red-carpet-robot':
      case '/contact':
      case '/gallery':
      default:
        this.setState({ color: 'white' })
        break;
    }
  }

  detectMouse = (event) => {
    window.clearTimeout( this.isMouse )
    this.isMouse = setTimeout(() => {
      if (!this.state.showHeader && event.clientY < 100) {
        // this.setState({ showHeader: true })
      }
    }, 0);
  }

  _setTransparent = () => {
    document.querySelector('header') && document.querySelector('header').classList.remove('header--scrolled')

    this.setState({ menuActive: false })
    document.querySelector('body') && document.querySelector('body').classList.remove('menu-open')
    document.querySelector('.logo') && document.querySelector('.logo').classList.remove('active')
    document.querySelector('.hamburger') && document.querySelector('.hamburger').classList.remove('active')
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body') && document.querySelector('body').classList.toggle('menu-open')
    document.querySelector('.logo') && document.querySelector('.logo').classList.toggle('active')
    document.querySelector('.hamburger') && document.querySelector('.hamburger').classList.toggle('active')
  }

  render() {
		let { color, menuActive } = this.state

    let props = {
      onClick: this._toggleOffCanvas,
      activeClassName: 'active',
    }

    let desktopProps = {
      onClick: this._setTransparent,
    }

    let className = 'header'

    return(
      <>
        <section className={className}>
          <div className='header__inner'>
            <Link to='/' className='logo' {...desktopProps}><Logo color={color} /></Link>
            <ul>
              <li>
                <Link to='/motion-control' {...desktopProps}>Motion Control</Link>
                <ul>
                  <li><Link to='/bolt-motion-control' {...desktopProps}>BOLT Cinebot</Link></li>
                  <li><Link to='/bolt-jr' {...desktopProps}>BOLT Jr+ Cinebot</Link></li>
                  <li><Link to='/cinebot-mini' {...desktopProps}>Cinebot Mini</Link></li>
                  <li><Link to='/motion-control/#compare' {...desktopProps}>Compare Cinebots</Link></li>
                  <li><Link to='/faq-motion-control' {...desktopProps}>Cinebot FAQs</Link></li>
                </ul>
              </li>
              <li>
                <Link to='/phantom4kveo' {...desktopProps}>Phantom 4k</Link>
                <ul>
                  <li><Link to='/faq-phantom' {...desktopProps}>Phantom FAQs</Link></li>
                </ul>
              </li>
              <li><Link to='https://sparklebot.com.au'>Event Activations</Link></li>
              <li><Link to='/post-production' {...desktopProps}>Production</Link></li>
              <li><Link to='/studios' {...desktopProps}>Studios</Link></li>
              <li><Link to='/gallery' {...desktopProps}>Gallery</Link></li>
              <li><Link to='/contact' {...desktopProps}>Contact</Link></li>
            </ul>
            <button className='hamburger' onClick={this._toggleOffCanvas} color={color} >
              <span className='lines' color={color} ></span>
            </button>
          </div>
        </section>
        <div className={`off-canvas ${menuActive ? 'off-canvas active' : 'off-canvas'}`}>
          <div className='off-canvas__inner'>
            <ul>
              <li>
                <Link to='/motion-control' {...props}>Motion Control</Link>
                <ul>
                  <li><Link to='/bolt-motion-control' {...props}>BOLT Cinebot</Link></li>
                  <li><Link to='/bolt-jr' {...props}>BOLT Jr+ Cinebot</Link></li>
                  <li><Link to='/cinebot-mini' {...props}>Cinebot Mini</Link></li>
                  <li><Link to='/motion-control/#compare' {...props}>Compare Cinebots</Link></li>
                  <li><Link to='/faq-motion-control' {...props}>Cinebot FAQs</Link></li>
                </ul>
              </li>
              <li>
                <Link to='/phantom4kveo' {...props}>Phantom 4k</Link>
                <ul>
                  <li><Link to='/faq-phantom' {...props}>Phantom FAQs</Link></li>
                </ul>
              </li>
              <li><Link to='https://sparklebot.com.au'>Event Activations</Link></li>
              <li><Link to='/post-production' {...props}>Production</Link></li>
              <li><Link to='/studios' {...props}>Studios</Link></li>
              <li><Link to='/gallery' {...props}>Gallery</Link></li>
              <li><Link to='/contact' {...props}>Contact</Link></li>
            </ul>
          </div>
        </div>
      </>
    )
  }
}

export default Header
